/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback, useEffect, useState,
} from 'react'
import _ from 'lodash'
import { cancelRequest, usePages, useI18n } from 'react-omnitech-api'
import { useAlert, useLink } from '../../hook'
import PagesView from './pages-view'

function PagesController(props) {
  // waiting API implementation
  const { id, slug } = props
  // prepare hook
  const alert = useAlert()
  const { recordNotFound } = useLink()
  const { fetchPage } = usePages()
  const { currentLanguage } = useI18n()

  // internal states
  const [page, setPage] = useState({})
  const [pageReady, setPageReady] = useState(false)

  // locale variable
  const seoTitle = page.metaTitle || page.title
  const seoDescription = page.metaDescription || page.excerpt
  const seoMeta = [{
    name: 'keyword',
    content: _.get(page, 'metaKeywords'),
  }]
  const seoLinks = [{
    rel: 'canonical',
    href: _.get(page, 'canonicalHref'),
  }]

  /**
   * fetchPageApi
   * get article content group data from API
   */
  const fetchPageApi = useCallback(async () => {
    try {
      // api call option
      const option = {
        method: _.toInteger(id) > 0 ? '' : 'slug',
        codeOrId: id || slug,
        includes: [
          'meta',
          'seo',
        ].join(','),
      }
      // call api
      const { page: data } = await fetchPage(option)
      setPage(data)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      if (generalError.code === 404) {
        recordNotFound()
        return
      }
      alert.show(generalError.message)
    } finally {
      setPageReady(true)
    }
  }, [fetchPage, slug, id, currentLanguage])

  /**
   * load article content group when page loaded
   */
  useEffect(() => {
    fetchPageApi()

    return function fetchPageApiCleanUp() {
      cancelRequest.cancelAll([
        'fetchPage',
      ])
    }
  }, [fetchPageApi])

  const viewProps = {
    page,
    pageReady,
    seoDescription,
    seoTitle,
    seoLinks,
    seoMeta,
  }

  return (
    <PagesView {...viewProps} />
  )
}

export default PagesController
